import classnames from 'classnames'

export const CleanValueIcon: React.FC<{
  className?: string
  onClick?: () => void
}> = ({ className, onClick }) => (
  <svg
    className={classnames(
      'absolute w-4 h-4 -translate-y-1/2 right-4 top-1/2 text-[#C4C4C4] cursor-pointer',
      className,
    )}
    viewBox="0 0 20 20"
    fill="currentColor"
    onClick={() => onClick?.()}
  >
    <path
      fillRule="evenodd"
      d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
      clipRule="evenodd"
    />
  </svg>
)
