import Header from '../components/Header'
import Footer from '../components/Footer'
import { useState, useEffect } from 'react'
import { mock } from 'mockjs'
import avatar from '../images/common/avater.png'
import { useMoralis } from '../hooks/moralis'
import ethIcon from '../images/common/ethIcon.png'
import nft1 from '../images/NFTRelated/nft1.png'
import eth from '../images/common/eth.svg'
import NFTCard from '../components/NFTCard'
import { NFTInfoType } from '../hooks/type'
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import Modal from '../components/Modal'
import { SubmitHandler, useForm } from 'react-hook-form'
import Popup from '../components/Popup'
import Toast from '../components/toast'
import { CleanValueIcon } from '../components/CleanValueIcon'
import { toast } from 'react-toastify'
import { nftContract, marketContract } from '../hooks/config'

const FeaturedDetails = ({ location }: { location: any }) => {
  const [userAddress, setUserAddress] = useState()
  const [NFTInfo, setNFTInfo] = useState<null | NFTInfoType>()
  const [price, setPrice] = useState<null | string>()
  const [item, setItem] = useState<null | string>()
  const [isOwned, setIsOwned] = useState<null | boolean>()
  const [moreList] = useState()
  const [sellPopupVisible, setSellPopupVisible] = useState(false)
  const [currentToken, setCurrentToken] = useState<any>()
  const [detailsList, setDetailsList] = useState<Record<string, string>>({
    'Contract Address': '',
    'Token ID': '',
    'Token Standard': '',
    Blockchain: '',
    Metadata: 'Centralized',
    'Creator Fees': '5%',
  })
  const {
    Moralis,
    getNFTMetadata,
    tranferNFTs,
    getSaleNFT,
    purchaseNFT,
    revokeNFT,
    approveSell,
    getApproveSell,
    listNFTs,
  } = useMoralis()
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setUserAddress(window?.ethereum?.selectedAddress)
      const info = location?.state?.NFTInfo
      console.log(info)
      const address = `${info.token_address.slice(
        0,
        5,
      )}....${info.token_address.slice(-4)}`
      if (info.metadata) {
        setNFTInfo(info)
      } else {
        fetch(info.token_uri)
          .then(res => res.json())
          .then(result => {
            console.log(result)
            setNFTInfo({ ...info, metadata: result })
          })
      }
      if (info.owner_of === marketContract) {
        getSaleNFT?.(info?.token_id)?.then(result => {
          console.log(result)
          setItem(result?.itemId)
          setIsOwned(result?.seller === window?.ethereum?.selectedAddress)
          const value = result?.price
            ? Moralis?.Units.FromWei(result?.price)
            : '0.00'
          setPrice(value)
        })
      } else {
        setIsOwned(info?.owner_of === window?.ethereum?.selectedAddress)
      }
      setDetailsList({
        'Contract Address': `${address}`,
        'Token ID': `${info?.token_id}`,
        'Token Standard': `${info?.contract_type}`,
        Blockchain: `${info?.metadata?.blockchain || ''}`,
        Metadata: 'Centralized',
        'Creator Fees': '5%',
      })
    }
  }, [])
  const [listings] = useState(
    new Array(10).fill('').map(() => ({
      Price: '0.05 ETH',
      USDPrice: '$98.66',
      Expiration: 'About 14 hours',
      From: 'BeardedApe',
    })),
  )
  const [buyNowModalVisible, setBuyNowModalVisible] = useState(false)
  const onBuyNowModalOk = () => {
    buyNowReset()
    document.body.style.overflow = 'auto'
    setBuyNowModalVisible(false)
  }
  const onBuyNowModalCancel = () => {
    buyNowReset()
    document.body.style.overflow = 'auto'
    setBuyNowModalVisible(false)
  }

  const {
    handleSubmit: buyNowHandleSubmit,
    register: buyNowRegister,
    reset: buyNowReset,
    formState: { errors: buyNowErrors },
  } = useForm<{
    quantity: string
    isAgree: boolean
  }>({})
  const onBuyNowSubmit: SubmitHandler<{
    quantity: string
    isAgree: boolean
  }> = data => {
    if (data?.isAgree) {
      console.log(price)
      purchaseNFT?.(item, price).then(res => {
        console.log(res)
      })
      onBuyNowModalOk()
    } else {
      toast.warn('Please agree to the contract first')
    }
  }

  // Transfer
  const [transferPopupVisible, setTransferPopupVisible] = useState(false)
  const onTransferPopupOk = () => {
    transferReset()
    document.body.style.overflow = 'auto'
    setTransferPopupVisible(false)
  }
  const onTransferPopupCancel = () => {
    transferReset()
    document.body.style.overflow = 'auto'
    setTransferPopupVisible(false)
  }

  const {
    register: transferRegister,
    handleSubmit: transferHandleSubmit,
    reset: transferReset,
    formState: { errors: transferErrors },
    watch: transferWatch,
    resetField: transferResetField,
  } = useForm<{ transferPrice: string }>()

  const onTransferSubmit: SubmitHandler<{ transferPrice: string }> = data => {
    const from =
      Moralis?.User.current()?.get('address') ??
      window?.ethereum?.selectedAddress
    // console.log(from, NFTInfo?.token_id, NFTInfo?.token_address)
    tranferNFTs?.(
      from,
      data.transferPrice,
      NFTInfo?.token_id,
      NFTInfo?.token_address,
    ).then((res: any) => {
      setTransferPopupVisible(false)
    })
  }
  const handleRevokeNFT = () => {
    revokeNFT?.(item).then((res: any) => {})
  }
  const onToSell = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // FIXME: to Sell is not
    const isApproved = '0x0000000000000000000000000000000000000000'
    getApproveSell?.(NFTInfo?.token_id, NFTInfo?.token_address).then(
      (res: any) => {
        if (res === isApproved) {
          approveSell?.(NFTInfo?.token_id, NFTInfo?.token_address).then(
            (res: any) => {
              if (res) {
                document.body.style.overflow = 'hidden'
                setSellPopupVisible(true)
              }
            },
          )
        } else {
          document.body.style.overflow = 'hidden'
          setSellPopupVisible(true)
        }
      },
    )
  }
  const {
    register: sellRegister,
    reset: sellReset,
    handleSubmit: sellHandleSUbmit,
    formState: { errors: sellErrors },
    watch: sellWatch,
    resetField: sellResetField,
  } = useForm<{ sellPrice: string }>()
  const onToSellSubmit: SubmitHandler<{ sellPrice: string }> = data => {
    listNFTs?.(NFTInfo?.token_id, NFTInfo?.token_address, data.sellPrice).then(
      res => {
        document.body.style.overflow = 'auto'
        setSellPopupVisible(false)
        sellReset()
      },
    )
  }
  return (
    <>
      <Header />
      <Toast limit={1} />
      <div className="bg-[#f3f7fa] pt-20">
        <div className="mx-auto max-w-7xl md:px-16 sm:px-4">
          <div className="min-h-[629px] flex pt-[93px] border-b border-[#E0E0E0] pb-16">
            <img
              src={NFTInfo?.metadata?.fileURI || nft1}
              alt="NFTImg"
              className="w-[549px] h-[468px] object-cover rounded-lg"
            />
            {/* Info */}
            <div className="ml-[45px] text-[#828282] w-full">
              <div className="flex items-center justify-between">
                <div>
                  Owned by{' '}
                  <span className="text-[#1E94FC]">
                    {NFTInfo?.owner_of === NFTInfo?.userInfo?.address
                      ? 'you'
                      : NFTInfo?.userInfo?.username}
                  </span>
                </div>
                {/* TODO: collect functionality */}
                {/*                 <div
                  className="border border-[#BDBDBD] rounded-[10px] w-[73px] h-[38px] flex justify-center items-center cursor-pointer select-none"
                  onClick={() => {
                    setNFTCollect({
                      collectState: !NFTCollect.collectState,
                      collect: !NFTCollect.collectState
                        ? NFTCollect.collect + 1
                        : NFTCollect.collect - 1,
                    })
                  }}
                >
                  <svg
                    className={`h-5 w-5 ${
                      NFTCollect.collectState
                        ? 'text-[#f87575]'
                        : 'text-[#BDBDBD]'
                    }`}
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <div className="ml-1 font-numberText">
                  </div>
                </div> */}
              </div>
              <div className="text-[34px] leading-[52px] font-bold font-boldText mt-2 text-[#000]">
                {NFTInfo?.metadata?.worksName || ''}
              </div>
              <div className="pb-6 border-b border-[#E0E0E0] flex">
                <div
                // className={`before:float-right before:w-0  ${
                //   showMoreDesc
                //     ? 'h-auto line-clamp-999 before:h-[calc(100%-24px)]'
                //     : 'line-clamp-2 h-[48px] before:h-6'
                // }`}
                >
                  {/* <span
                    className="cursor-pointer text-[#F87575] float-right clear-both"
                    onClick={() => setShowMoreDesc(!showMoreDesc)}
                  >
                    {showMoreDesc ? 'Less' : 'More Details'}
                  </span> */}
                  {NFTInfo?.metadata?.description || ''}
                </div>
              </div>
              <div className="mt-8">Current price</div>
              <div className="flex items-center mt-6 font-numberText">
                {/*                 <img
                  src={NFTInfo?.userInfo?.avatarUrl || avatar}
                  alt="userAvatar"
                  className="w-[26px] h-[26px] mr-2"
                /> */}
                {/* <span>{NFTInfo?.userInfo?.username}</span> */}
                <div className="ml-2 text-[#1E94FC] text-[32px] leading-6 flex flex-row">
                  <div className="w-[24px] h-[24px]">
                    <img className="h-full" src={eth} alt="alt" />
                  </div>
                  <span>{price || '0.00'}</span>
                </div>
                <div className="self-end ml-3 text-[14px] leading-4">
                  {/* ($123) */}
                </div>
              </div>
              {isOwned ? (
                price ? (
                  <button
                    className="mt-[44px] rounded-[68px] bg-[#BDBDBD] text-lg text-white w-[155px] h-12"
                    onClick={handleRevokeNFT}
                  >
                    Revoke
                  </button>
                ) : (
                  <button
                    className="mt-[44px] rounded-[68px] bg-[#1E94FC] text-lg text-white w-[155px] h-12"
                    onClick={onToSell}
                  >
                    Sell
                  </button>
                )
              ) : price ? (
                <button
                  className="mt-[44px] rounded-[68px] bg-[#1E94FC] text-lg text-white w-[155px] h-12"
                  onClick={() => {
                    document.body.style.overflow = 'hidden'
                    setBuyNowModalVisible(true)
                  }}
                >
                  Buy now
                </button>
              ) : (
                ''
              )}
              <div className="flex items-center mt-9">
                <svg
                  className="w-4 h-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <div className="ml-1 text-sm">
                  {dayjs(NFTInfo?.last_token_uri_sync)
                    .locale('en')
                    .format('ddd MMM D YYYY')}
                </div>
              </div>
              <div className="mt-3 flex justify-end h-[34px] overflow-hidden">
                {userAddress === NFTInfo?.owner_of ? (
                  <div
                    className="w-[34px] h-[34px] bg-[url('../images/NFTRelated/transfer.png')] hover:bg-[url('../images/NFTRelated/activeTransfer.png')] bg-cover cursor-pointer mr-3"
                    onClick={() => {
                      document.body.style.overflow = 'hidden'
                      setTransferPopupVisible(true)
                    }}
                  ></div>
                ) : (
                  ''
                )}
                <div className="w-[34px] h-[34px] bg-[url('../images/NFTRelated/refresh.png')] hover:bg-[url('../images/NFTRelated/activeRefresh.png')] bg-cover cursor-pointer mr-3"></div>
                <div className="w-[34px] h-[34px] bg-[url('../images/NFTRelated/more.png')] hover:bg-[url('../images/NFTRelated/activeMore.png')] bg-cover cursor-pointer mr-3"></div>
                <div className="w-[34px] h-[34px] bg-[url('../images/NFTRelated/share.png')] hover:bg-[url('../images/NFTRelated/activeShare.png')] bg-cover cursor-pointer"></div>
              </div>
            </div>
          </div>
          {/* Card Content */}
          <div className="mt-8 flex py-[30px]">
            {/* Details */}
            <div className="w-[406px] border border-[#E0E0E0] rounded-lg py-8 px-[26px]">
              <div className="flex items-center">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
                  />
                </svg>
                <div className="text-[18px] leading-4 ml-[10px]">Details</div>
              </div>
              <div className="mt-10">
                {Object?.keys(detailsList).map((key, i) => (
                  <div
                    key={i}
                    className="flex justify-between mt-8 first:mt-0 group"
                  >
                    <div className="text-[#4F4F4F] leading-4">{key}</div>
                    <div className="text-[#828282] group-first:text-[#1E94FC] leading-4 font-numberText">
                      {detailsList?.[key] || ''}
                    </div>
                  </div>
                )) || ''}
              </div>
            </div>
            {/* Listings */}
            <div className="flex-1 border border-[#E0E0E0] rounded-lg py-8 px-[26px] ml-[30px]">
              <div className="flex items-center">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
                  />
                </svg>
                <div className="text-[18px] leading-4 ml-[10px]">Listings</div>
              </div>
              <div className="mt-10 text-[#4F4F4F] h-9 border-b border-[#E0E0E0]">
                <div className="grid grid-cols-4">
                  {['Price', 'USD Price', 'Expiration', 'From'].map((x, i) => (
                    <div key={i}>{x}</div>
                  ))}
                </div>
              </div>
              <div className="text-[#4F4F4F] pt-[18px] max-h-[220px] overflow-hidden scrollbar-style overflow-y-auto">
                {listings.map((x, i) => (
                  <div
                    key={i}
                    className="grid grid-cols-4 mt-8 leading-4 first:mt-0"
                  >
                    <div className="font-numberText">{x.Price}</div>
                    <div className="font-numberText">{x.USDPrice}</div>
                    <div>{x.Expiration}</div>
                    <div className="text-[#1E94FC]">{x.From}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* More From This Collection */}
          <div className="flex py-[30px] overflow-hidden">
            <div className="w-full border border-[#E0E0E0] rounded-lg py-8 px-[26px]">
              <div className="flex items-center">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
                  />
                </svg>
                <div className="text-[18px] leading-4 ml-[10px]">
                  More From This Collection
                </div>
              </div>
              <div className="mt-10">
                <div className="mt-8 grid grid-cols-4 gap-x-[30px] gap-y-[33px]">
                  {moreList?.map((x, i) => (
                    <NFTCard
                      key={i}
                      NFTInfo={x}
                      width="284px"
                      height="352px"
                      imgHeight="186px"
                    />
                  )) || ''}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer className="bg-[#f3f7fa]" />
      <Modal
        visible={buyNowModalVisible}
        onOk={onBuyNowModalOk}
        onCancel={onBuyNowModalCancel}
      >
        <form onSubmit={buyNowHandleSubmit(onBuyNowSubmit)}>
          <div className="text-[34px] leading-[52px] font-boldText font-bold">
            Complete checkout
          </div>
          <div className="mt-[33px] bg-white rounded-2xl py-[21px] px-5 flex">
            <img
              src={NFTInfo?.metadata?.fileURI}
              alt="NFTImg"
              className="w-[145px] h-full rounded-lg"
            />
            <div className="ml-[18px] flex flex-col justify-center">
              <div className="text-[18px] leading-[32px] font-boldText font-bold">
                {NFTInfo?.metadata?.worksName} # {NFTInfo?.token_id}
              </div>
              <div className="text-[#828282] mt-3 text-[14px] leading-[22px]">
                Owned by{' '}
                <span className="text-[#1E94FC]">
                  {NFTInfo?.userInfo?.username}
                </span>
              </div>
            </div>
          </div>
          {/* Transaction Details */}
          <div className="mt-8 border-b border-[#E0E0E0] pb-6">
            <div className="font-bold font-boldText">Transaction Details</div>
            <div className="flex justify-between mt-6 h-[34px] items-center">
              <div className="text-[#4F4F4F]">Quantity</div>
              <input
                defaultValue={1}
                {...buyNowRegister('quantity', {
                  min: 1,
                  max: 99,
                })}
                type="number"
                readOnly
                className={`w-[38px] h-[38px] rounded-md flex justify-center items-center font-numberText outline-none p-1 text-center border-solid border ${
                  buyNowErrors.quantity ? ' border-red-500' : 'border-[#BDBDBD]'
                }`}
              />
            </div>
            <div className="flex justify-between mt-6 h-[34px] items-center">
              <div className="text-[#4F4F4F]">Creator Fees</div>
              <div className="font-numberText">5%</div>
            </div>
            <div className="flex justify-between mt-6">
              <div className="text-[#4F4F4F]">Unit price</div>
              <div className="flex flex-col items-end">
                <div className="flex items-center">
                  <img
                    src={
                      Moralis?.User.current()?.attributes?.avatar?._url ??
                      avatar
                    }
                    alt="userAvatar"
                    className="w-5 h-5 rounded-full"
                  />
                  <div className="ml-2 font-numberText text-[#1E94FC] text-[20px] font-semibold leading-6">
                    {price}
                  </div>
                </div>
                <div className="font-numberText text-[14px] leading-[16px] text-[#4F4F4F] mt-2">
                  {/* ($98.79) */}
                </div>
              </div>
            </div>
          </div>
          {/* Total */}
          <div className="flex justify-between mt-8">
            <div className="text-[#4F4F4F]">Total</div>
            <div className="flex flex-col items-end">
              <div className="text-[#4F4F4F] text-[14px] leading-[16px]">
                X1
              </div>
              <div className="flex items-center mt-2">
                <img
                  src={
                    Moralis?.User.current()?.attributes?.avatar?._url ?? avatar
                  }
                  alt="userAvatar"
                  className="w-5 h-5 rounded-full"
                />
                <div className="ml-2 font-numberText text-[#1E94FC] text-[20px] font-semibold leading-6">
                  {price}
                </div>
              </div>
              <div className="font-numberText text-[14px] leading-[16px] text-[#4F4F4F] mt-2">
                {/* ($98.79) */}
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              {...buyNowRegister('isAgree')}
              className="w-4 h-4"
            />
            <div className="text-[14px] leading-[18px] text-[#BDBDBD] ml-1">
              Agree to the{' '}
              <span className="text-[#1E88E5]">Terms of Service</span>
            </div>
          </div>
          <button
            type="submit"
            className="text-white w-full h-[52px] text-[18px] leading-[30px bg-[#1E94FC] mt-[18px] rounded"
          >
            Checkout
          </button>
        </form>
      </Modal>
      <Popup
        visible={sellPopupVisible}
        onOk={() => {
          document.body.style.overflow = 'auto'
          setSellPopupVisible(false)
        }}
        onCancel={() => {
          document.body.style.overflow = 'auto'
          setSellPopupVisible(false)
        }}
      >
        <div className="text-[34px] leading-[52px] font-boldText font-bold">
          Set sales price
        </div>
        {/* FIXME: Sell form*/}
        <form
          onSubmit={sellHandleSUbmit(onToSellSubmit)}
          className="mt-8 w-full"
        >
          <div className="relative">
            <input
              {...sellRegister('sellPrice', { required: true, min: 0 })}
              type="text"
              className={`w-full h-[62px] py-[18px] pl-4 pr-20 font-numberText placeholder:text-[16px] placeholder:leading-[22px] overflow-hidden outline-none bg-[rgba(255,255,255,0.8)] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] hover:shadow-[0_6px_18px_4px_#C0E2FF] focus:shadow-[0_6px_18px_4px_#C0E2FF] rounded border border-solid ${
                sellErrors.sellPrice
                  ? 'border-red-500 focus:shadow-none hover:shadow-none'
                  : 'border-transparent focus:border-[#1E94FC] hover:border-[#1E94FC]'
              }`}
              placeholder="Your selling price"
            />
            <div className="flex flex-row absolute top-1/2 right-4 -translate-y-1/2">
              <span>ETH</span>
              <img src={ethIcon} alt="ethIcon" className="w-6 h-6 ml-1" />
            </div>

            {sellWatch('sellPrice') && (
              <CleanValueIcon
                onClick={() => sellResetField('sellPrice')}
                className="right-14"
              />
            )}
          </div>
          <div className="mt-8">
            <button
              type="submit"
              className="w-[116px] h-[50px] bg-[#1E94FC] rounded-[4px] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] text-white leading-[22px]"
            >
              Complete
            </button>
            <button
              type="button"
              className="w-[96px] h-[50px] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] rounded border border-[#BDBDBD] text-[#BDBDBD] ml-4"
              onClick={() => {
                sellReset()
                document.body.style.overflow = 'auto'
                setSellPopupVisible(false)
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </Popup>
      <Popup
        visible={transferPopupVisible}
        onOk={onTransferPopupOk}
        onCancel={onTransferPopupCancel}
        width="824px"
      >
        <div className="text-[34px] leading-[52px] font-boldText font-bold">
          Transfer
        </div>
        <form
          className="mt-6 bg-white p-[20px] rounded-2xl flex"
          onSubmit={transferHandleSubmit(onTransferSubmit)}
        >
          <div className="w-[248px] h-[212px] rounded-lg overflow-hidden">
            <img
              src={NFTInfo?.metadata?.fileURI}
              alt="NFTImg"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="ml-8 flex-1">
            <div className="leading-[22px] mt-3">
              “{NFTInfo?.metadata?.worksName?.slice(0, 8)} ...”will be
              transferred to
            </div>
            <div className="mt-[22px] relative">
              <input
                {...transferRegister('transferPrice', {
                  required: true,
                  min: 0,
                })}
                type="text"
                className={`w-full h-[62px] p-[18px] pr-10 font-numberText placeholder:text-[16px] placeholder:leading-[22px] overflow-hidden outline-none bg-[rgba(255,255,255,0.8)] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] hover:shadow-[0_6px_18px_4px_#C0E2FF] focus:shadow-[0_6px_18px_4px_#C0E2FF] rounded border border-solid ${
                  transferErrors.transferPrice
                    ? 'border-red-500 focus:shadow-none hover:shadow-none'
                    : 'border-transparent focus:border-[#1E94FC] hover:border-[#1E94FC]'
                }`}
                placeholder="transfer to address"
              />
              {transferWatch('transferPrice') && (
                <CleanValueIcon
                  onClick={() => transferResetField('transferPrice')}
                />
              )}
            </div>
            <div className="mt-8">
              <button
                type="submit"
                className="w-[116px] h-[50px] bg-[#1E94FC] rounded-[4px] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] text-white leading-[22px]"
              >
                Complete
              </button>
              <button
                type="button"
                className="w-[96px] h-[50px] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] rounded border border-[#BDBDBD] text-[#BDBDBD] ml-4"
                onClick={() => {
                  transferReset()
                  document.body.style.overflow = 'auto'
                  setTransferPopupVisible(false)
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </Popup>
    </>
  )
}

export default FeaturedDetails
