type ModalPropsType = {
  visible: Boolean
  children?: React.ReactNode
  onOk(): void
  onCancel(): void
}
/**
 * @param visible: Whether the modal dialog is visible or not
 * @callback onOk: Specify a function that will be called when a user clicks the OK button
 * @callback onCancel: Specify a function that will be called when a user clicks mask, close button on top right or Cancel button
 * @returns
 */
const Modal: React.FC<ModalPropsType> = ({
  visible,
  children,
  onOk,
  onCancel,
}) => {
  return (
    <div className="overflow-hidden font-regularText">
      <div
        className={`py-20 overflow-auto w-full h-screen top-0 left-0 fixed transition-all z-[999] backdrop-saturate-[180%] backdrop-blur-[3px] bg-[rgba(255,255,255,0.75)] sm:py-0  ${
          visible ? 'flex' : 'hidden'
        }`}
      >
        <div
          className={`w-[640px] m-auto bg-gradient-to-t from-[rgba(255,255,255,1)] to-[rgba(234,244,252,0.71)] shadow-[0_0_24px_1px_rgba(0,0,0,0.1)] border border-[#fff] border-solid rounded-2xl p-10 relative sm:w-auto sm:px-6 sm:pt-[26px] sm:pb-[48px] sm:rounded-none`}
        >
          {/* Mobile Title */}
          <div className="hidden h-[30px] sm:grid grid-cols-3 items-center mb-[34px] cursor-pointer">
            <svg
              className="w-5 h-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              onClick={() => onCancel()}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 19l-7-7 7-7"
              />
            </svg>
            <div className="text-[22px] leading-[30px] justify-self-center font-boldText">
              $CTIO
            </div>
          </div>
          {/* Modal Content */}
          {children}
          {/* Close Icon */}
          <div
            className="absolute top-4 right-5 text-[#BDBDBD] cursor-pointer sm:hidden"
            onClick={() => onCancel?.()}
          >
            <svg
              className="w-[26px] h-[26px]"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
